<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showTableOverlay" rounded="sm">
      <b-card style="max-width: 100%" class="mb-2">
        <b-table
          id="table"
          striped
          bordered
          :items="response.data"
          @sort-changed="sortingChanged"
          no-local-sorting
          responsive
          selectable
          select-mode="single"
          :fields="fields"
          @row-selected="onRowsSelected"
        ></b-table>

        <b-dropdown
          id="dropdown-show-by"
          :text="'Show by: ' + this.perPage"
          class="m-md-2"
          offset="-50"
          style="float: right"
        >
          <b-dropdown-item
            v-for="count in showPerPageArray"
            :key="count"
            @click="showPerPageChanged(count)"
            >{{ count }}</b-dropdown-item
          >
        </b-dropdown>

        <div
          v-bind:style="
            response.data.length === 0 || itemsTotal < perPage
              ? 'display:none;'
              : null
          "
        >
          <b-pagination
            v-model="currentPage"
            :page-class="!calculate ? 'hidden' : null"
            :last-class="!calculate ? 'hidden' : null"
            :first-class="!calculate ? 'hidden' : null"
            :hide-ellipsis="!calculate"
            :size="!calculate ? 'lg' : null"
            :per-page="perPage"
            @change="onPageSelected"
            :total-rows="itemsTotal"
            aria-controls="table"
          >
          </b-pagination>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import Common from "../../common";
import Application from "../../application";

export default {
  name: "personal-notifications",
  components: {},
  data() {
    return {
      fields: [
        {
          key: "created",
          formatter: Common.idObjectFormatter,
          label: this.$t("COMMON.CREATED"),
          sortable: true,
          sortDirection: "desc",
          sortingKey: "el.created",
        },
        { key: "title", label: this.$t("COMMON.TITLE"), sortable: false },
        {
          key: "notificationStateId",
          label: this.$t("PERSONAL_NOTIFICATIONS.IS_READ"),
          sortable: false,
          formatter: this.customFormatter,
        },
      ],

      response: {
        data: [],
        totalCount: 1,
      },

      getList: {
        resource: "/api/personal-notification",
        requestType: "GET",
        requestParams: { enrich: true },
      },
      calculate: false,

      currentPage: 1,
      perPage: 10,
      showPerPageArray: [10, 50, 100],

      selectedItem: null,
      filterItems: [],
      filterItem: "",

      showTableOverlay: true,
    };
  },
  computed: {
    itemsTotal() {
      return this.response.totalCount;
    },
  },
  created() {
    this.getList.requestParams.count = this.perPage;
    this.getList.requestParams.calculate = this.calculate;
    this.loadList();
  },
  mounted() {
    let that = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.PERSONAL_NOTIFICATIONS") },
    ]);
    this.$store.dispatch(SET_ACTIONS, [
      {
        label: this.$t("ACTIONS.SHOW"),
        action: this.onShowAction,
        disabled: function () {
          return that.selectedItem === null || that.showTableOverlay;
        },
        icon: "fas fa-edit",
      },
      {
        label: this.$t("ACTIONS.RELOAD"),
        action: this.onReloadAction,
        icon: "fas fa-sync",
      },
    ]);
  },
  methods: {
    customFormatter: function (value, key) {
      const k = key.toUpperCase();
      switch (k) {
        case "NOTIFICATIONSTATEID": {
          if (value == Application.constants.NOTIFICATION_STATES.DELIVERED) {
            return "Yes";
          } else {
            return "No";
          }
        }
      }
    },

    loadList: function () {
      this.showTableOverlay = true;
      this.$store
        .dispatch(API_REQUEST, this.getList)
        .then((response) => {
          this.response = Common.processEntityListResponse(
            this.response,
            response,
            this.currentPage,
            this.perPage,
            this.calculate
          );
          this.currentPage = this.response.noData
            ? this.currentPage - 1
            : this.currentPage;
          this.showTableOverlay = false;
        })
        .catch(this.onError);
    },

    onError: function (response) {
      if (response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message ? response.message : "Unknown error",
        {
          title: "Error",
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },

    onReloadAction: function () {
      this.getList.requestParams.start = 0;
      this.currentPage = 1;
      this.loadList();
    },

    onPageSelected: function (index) {
      this.getList.requestParams.start = (index - 1) * this.perPage;
      this.loadList();
    },

    onRowsSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedItem = items[0];

        this.$bus.$emit("dropdown_actions:animate");
      } else {
        this.selectedItem = null;
      }
    },

    sortingChanged: function (ctx) {
      this.currentPage = 1;
      this.getList.requestParams.sortDir = ctx.sortDesc ? "desc" : "asc";

      let field = this.fields.find((x) => x.key === ctx.sortBy);
      if (field != null && field.sortingKey != null) {
        this.getList.requestParams.sortField = field.sortingKey;
      } else {
        this.getList.requestParams.sortField = ctx.sortBy;
      }
      this.getList.requestParams.start = 0;
      this.loadList();
    },

    showPerPageChanged: function (count) {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams.count = count;
      this.perPage = count;
      this.loadList();
    },

    onShowAction: function () {
      this.$router.push({
        name: "personal-notification",
        params: { id: this.selectedItem.id },
      });
    },
  },
};
</script>
